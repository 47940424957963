import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import filtersEmptyState from "../assets/empty_states/filtersES.jpg";
import Autocomplete from "@mui/material/Autocomplete";

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import LocationIcon from "../assets/icons/location.png";
import TagIcon from "../assets/icons/tag.png";
import MovieIcon from "../assets/icons/movie.png";
import ArrowMenu from "../assets/icons/filtersArrow.png";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { withStyles } from "@mui/styles";
import Unchecked from "../assets/icons/unchecked.png";
import CheckedFilm from "../assets/icons/checkedFilm.png";
import CheckedExperiences from "../assets/icons/checkedExperiences.png";
import CheckedAboutTheFilm from "../assets/icons/checkedAboutTheFilm.png";
import FilmContentIcon from "../assets/icons/ContentIconCard.png";
import FilmIcon from "../assets/icons/maxFilmIcon.png";
import ExperiencesIcon from "../assets/icons/experienceIconOutlined.png";

import { useTranslation } from "react-i18next";

import "./Filters.css";
import "../App.css";

const Filters = ({
  filters,
  handleChange,
  handleChangeTypeContent,
  appliedFilters,
  removeFilter,
  tagFilter,
  clearFilters,
  searchValue,
  windowWidth,
  loadedTags,
}) => {
  const { t } = useTranslation();

  const [typeCheckboxes, setTypeCheckboxes] = useState([]);

  const typeFilters = filters.filter((item) => item.type === "type");
  const generalFilters = filters.filter((item) => item.type !== "type");

  const labelIcons = {
    Film: FilmIcon,
    Experiences: ExperiencesIcon,
    AbouttheFilm: FilmContentIcon,
  };
  const checkedIcons = {
    Film: CheckedFilm,
    Experiences: CheckedExperiences,
    AbouttheFilm: CheckedAboutTheFilm,
  };
  const CustomCheckbox = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: "white",
      },
    },
    checked: {},
  })((props) => (
    <Checkbox
      color="default"
      {...props}
      icon={<img src={Unchecked} style={{ marginRight: "10px", width: "25px" }} alt="Unchecked" />}
      checkedIcon={
        <img
          src={checkedIcons[props.label.replaceAll(" ", "")]}
          style={{ marginRight: "10px", width: "25px" }}
          alt="Checked"
        />
      }
    />
  ));

  useEffect(() => {
    const checkboxesData = typeFilters.map((filter) => ({
      ...filter,
      checked: true,
    }));

    setTypeCheckboxes(checkboxesData);
  }, [loadedTags]);

  useEffect(() => {
    const updatedFilters = typeCheckboxes.filter((t) => t.checked);
    handleChangeTypeContent(updatedFilters);
  }, [typeCheckboxes]);

  const handleCheckboxChange = (type) => (event) => {
    const isChecked = event.target.checked;
    setTypeCheckboxes((prevState) => {
      const typeIdx = prevState.findIndex((filter) => filter.value === type);

      const clonedArray = [...prevState];
      clonedArray[typeIdx].checked = isChecked;

      return clonedArray;
    });
  };

  return (
    <>
      <input type="checkbox" id="menuSwitcher" />

      {windowWidth < 480 && (
        <label className="smallFilterBtn" htmlFor="menuSwitcher">
          <div />
          <div />
          <div />
        </label>
      )}

      <div className="filters">
        {windowWidth >= 480 && (
          <label className="sideSwitcher" htmlFor="menuSwitcher">
            <img src={ArrowMenu} alt="filters icon" />
          </label>
        )}

        <div className="searchSection">
          <div className="autocomplete">
            <h1>{t("Filters.search_by")}</h1>
            <form>
              <Autocomplete
                disablePortal
                key={appliedFilters.length}
                options={generalFilters}
                className="acComponent"
                value={searchValue}
                onChange={handleChange}
                sx={{ width: 300 }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <div {...props}>
                    {option.type === "tag" && (
                      <span className="autocompleteOption">
                        <img alt="filter icon" src={TagIcon} />
                        {option.name}
                      </span>
                    )}
                    {option.type === "location" && (
                      <span className="autocompleteOption">
                        <img alt="filter icon" src={LocationIcon} />
                        {option.name}
                      </span>
                    )}
                    {option.type === "movie" && (
                      <span className="autocompleteOption">
                        <img alt="filter icon" src={MovieIcon} /> {option.name}
                      </span>
                    )}
                  </div>
                )}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      type="text"
                      {...params.inputProps}
                      value={searchValue}
                      onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                      placeholder={t("Filters.input_placeholder")}
                    />
                  </div>
                )}
              />
            </form>
          </div>

          <div className="tagsSection">
            <h1>{t("Filters.popular_tags")}</h1>

            <Grid container spacing={1} className="tagsGrid">
              {filters?.map((tag, index) =>
                filters.length > 0 && index < 9 && tag.type === "tag" ? (
                  <Grid key={tag.id} item xs={4}>
                    <button onClick={() => tagFilter(tag)}>{tag?.name}</button>
                  </Grid>
                ) : (
                  <span key={tag.id}></span>
                ),
              )}
            </Grid>
          </div>
        </div>

        <h1 className="appliedFiltersTitle">{t("Filters.applied_filters")}</h1>
        <div className="appliedFilters">
          <Grid container rowSpacing={1} columnSpacing={1} className="appliedGrid">
            {appliedFilters?.length > 0 ? (
              appliedFilters.map(
                (filter) =>
                  filter && (
                    <Grid key={filter.id} item className="appliedGrid">
                      <div className="appliedBtn">
                        <ClearRoundedIcon onClick={() => removeFilter(filter.id)}></ClearRoundedIcon>
                        <span className="appliedSpan">
                          <label> {filter?.name}</label>
                          <span>{filter?.type}</span>
                        </span>
                      </div>
                    </Grid>
                  ),
              )
            ) : (
              <Grid className="filtersEmptyState" item xs={12}>
                <div>
                  <img alt="filters empty state" src={filtersEmptyState} />
                  <p>{t("Filters.empty_state")}</p>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
        <div className="clearBtn">
          <button onClick={() => clearFilters()}>{t("Filters.clear_filters")}</button>
        </div>
      </div>

      <div className="filtersType">
        <FormGroup>
          {typeCheckboxes.map((type, index) => (
            <div key={index} className={`formCtrl tag-${type.value?.replaceAll(" ", "")}`}>
              <img className="iconsType" src={labelIcons[type.value?.replaceAll(" ", "")]} alt="Icon" />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={type.checked}
                    onChange={handleCheckboxChange(type.value)}
                    label={type.value}
                  />
                }
                label={t(type.name)}
                labelPlacement="start"
              />
            </div>
          ))}
        </FormGroup>
      </div>
    </>
  );
};

export default Filters;
