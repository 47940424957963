import React from 'react';
import { useTranslation } from "react-i18next";

import europeFlag from '../../assets/creative-europe.png';
import './Footer.css';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="secondaryFooterContainer">
      <div className="secondaryFooter">
        <img
          className="footerEurope"
          src={europeFlag}
          alt="Europe Flag"
        />

        <div className="footerText">
          <p>
            {t("Footer.text")}
          </p>
        </div>
      </div>
    </div>
  );
}

