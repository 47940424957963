import React, { useCallback, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ReactLoading from "react-loading";
import { CardActionArea } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getTypeTranslation } from "../../utils/utils";
import settings from "../../api/config";

import FilmContentIcon from "../../assets/icons/ContentIconCard.png";
import FilmIcon from "../../assets/icons/filmIconCard.png";
import ExperiencesIcon from "../../assets/icons/ExperienceIconCard.png";
import LineIcon from "../../assets/icons/Line.png";
import "./CardMovie.css";

const CardMovie = ({ movieLocation, onClick, selectedLang }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [pinLocation, setPinLocation] = useState(movieLocation);

  const fetchLocation = async (locationId) => {
    try {
      const url = `${settings.BASE_URL}/movie-locations/card/${locationId}`;
      const res = await fetch(url);
      const data = await res.json();
      return data;
    } catch (error) {
      console.log("Error fetching movie pinLocation by ID:", error);
      throw error;
    }
  };

  const loadMovieData = useCallback(async (locationId) => {
    setLoading(true);
    try {
      const data = await fetchLocation(locationId);
      setPinLocation(data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const setFontColor = () => {
    switch (pinLocation?.type) {
      case "Film":
        return "#D4AF37";
      case "About the Film":
        return "#009999";
      case "Experiences":
        return "#391D69";
      default:
        return "#000000";
    }
  };

  const getMovieTitle = () => {
    const translationIdx = pinLocation?.movie.Translations?.findIndex(
      (object) => object.__component === `translations.${selectedLang}`,
    );

    const translatedTitle = translationIdx ? pinLocation.movie?.Translations[translationIdx]?.TitleTranslation : "";
    const movieTitle = translatedTitle || pinLocation.movie?.OriginalTitle;
    return movieTitle;
  };

  const getCardImage = () => {
    let cardImage = "";

    if (pinLocation?.type === "Film") {
      const movieImg = pinLocation.movie.CollectionPhotogram;
      cardImage = movieImg?.formats?.small ? movieImg?.formats?.small.url : movieImg?.url;
    } else {
      const pinImg = pinLocation.Images.length > 0 ? pinLocation.Images[0] : "";
      cardImage = pinImg && pinImg?.formats?.small ? pinImg?.formats?.small.url : pinImg.url;
    }

    return cardImage;
  };

  useEffect(() => {
    loadMovieData(movieLocation._id);
  }, [loadMovieData, movieLocation]);

  return (
    <Card sx={{ width: 250 }} onClick={onClick}>
      <CardActionArea>
        {loading ? (
          <div className="cardLoadingContainer">
            <ReactLoading type="spin" color="#08798E" />
          </div>
        ) : (
          <CardMedia
            component="img"
            height="150"
            className="movieCard"
            image={`${settings.BASE_URL}${getCardImage()}`}
            alt="Film background image"
          />
        )}

        <CardContent style={{ padding: "0px" }}>
          <Typography variant="p" component="div" align="center" fontWeight="bold" paddingTop="10px">
            {pinLocation?.type === "Film" ? getMovieTitle() : pinLocation?.title}
          </Typography>
          <Typography gutterBottom variant="p" component="div" align="center">
            <img src={LineIcon} alt="Line divider" />
          </Typography>
          <Typography
            variant="p"
            component="div"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            fontWeight="bold"
            fontSize="12px"
            textTransform="uppercase"
            paddingBottom="10px"
            color={setFontColor()}
          >
            {t(getTypeTranslation(pinLocation?.type))}
            {pinLocation?.type === "Film" && <img src={FilmIcon} className="iconsType" alt="About the film icon" />}
            {pinLocation?.type === "About the Film" && (
              <img src={FilmContentIcon} className="iconsType" alt="Film content icon" />
            )}
            {pinLocation?.type === "Experiences" && (
              <img src={ExperiencesIcon} className="iconsType" alt="Experiences icon" />
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardMovie;
