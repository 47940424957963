import React from "react";
import { useState, useEffect } from "react";

import PlayIcon from "@mui/icons-material/PlayArrowRounded";
// import LocationIcon from "@mui/icons-material/FmdGood";
import ArrowDown from "@mui/icons-material/KeyboardArrowDownRounded";

import Collapse from "@mui/material/Collapse";

import CloseIcon from "@mui/icons-material/CloseRounded";

import { useTranslation } from "react-i18next";

import MovieIcon from "../../assets/icons/movieIcon.png";
import LocationIcon from "../../assets/icons/locationIcon.png";
import FilmIconMini from "../../assets/icons/film.png";
import ExperienceIconMini from "../../assets/icons/experiences.png";
import ContentIconMini from "../../assets/icons/filmContent.png";

import "./Mobile.css";

import Carousel from "../Carousel/MobileCarousel";

import { HideAndShowTxtComponent } from "../../utils/utils";

const Mobile = ({ image, info, selectedLang, handleModal, filterLocation }) => {
  const [imgCollapse, setImgCollapse] = useState(false);
  const [vidCollapse, setVidCollapse] = useState(false);
  const [linkCollapse, setLinkCollapse] = useState(false);
  const [audioCollapse, setAudioCollapse] = useState(false);
  const [docCollapse, setDocCollapse] = useState(false);

  const { t } = useTranslation();

  console.log("INFORMATION: ", info);

  const setBackColor = (type) => {
    switch (type) {
      case "Film":
        return "#D4AF37";
      case "About the Film":
        return "#009999";
      case "Experiences":
        return "#391D69";
      default:
        return "#000000";
    }
  };

  return (
    <div className="mobileModal">
      <CloseIcon
        className="mobileInfoClose"
        onClick={() => {
          handleModal();
        }}
      />
      <div className="mobileHeader">
        <div
          style={{
            backgroundColor: setBackColor(info?.type),
            height: "280px",
            backgroundImage: info?.type !== "Film" ? "none" : `url(${image})`,
          }}
        >
          {info?.type === "Film" ? (
            <div className="mobileOverlay"></div>
          ) : (
            <></>
          )}
          <div className="headerText">
            {info?.type === "About the Film" || info?.type === "Experiences" ? (
              <label style={{ fontSize: "30px", paddingBottom: "10px" }}>
                {info?.transTitle === "undefined" || !info?.transTitle
                  ? info?.title
                  : info?.transTitle}
              </label>
            ) : (
              <label style={{ fontSize: "30px", paddingBottom: "10px" }}>
                {info?.translatedTitle}
              </label>
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  textTransform: "uppercase",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={MovieIcon} />
                {info?.origMovTitle}
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={LocationIcon} />
                {info?.location}
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "uppercase",
                }}
              >
                {info?.type === "Film" ? <img src={FilmIconMini} /> : <></>}
                {info?.type === "About the Film" ? (
                  <img src={ContentIconMini} />
                ) : (
                  <></>
                )}
                {info?.type === "Experiences" ? (
                  <img src={ExperienceIconMini} />
                ) : (
                  <></>
                )}

                {info?.type}
              </label>
            </div>
            <div className="modalTags">
              {info?.tags &&
                info?.tags.map((tag) => (
                  <span
                    key={tag.id}
                    className={`movieTag tag-${info?.type?.replaceAll(
                      " ",
                      ""
                    )}`}
                  >
                    {tag?.TagName}
                  </span>
                ))}
            </div>
          </div>
          <div className="headerBtns">
            <a
              href={"https://www.cined.eu/movies/" + info?.id}
              target="__blank"
            >
              <button className="mobileBtn">
                <PlayIcon />
                <p className="watchText">{t("MovieModal.watch")}</p>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="modalBody">
        {info?.type === "About the Film" || info?.type === "Experiences" ? (
          <HideAndShowTxtComponent
            text={
              info?.transDescription === "undefined" || !info?.transDescription
                ? info?.description
                : info?.transDescription
            }
            color={"#EE940D"}
          />
        ) : (
          <HideAndShowTxtComponent text={info?.synopsis} color={"#EE940D"} />
        )}
      </div>
      <div className="mobileGallery">
        {info?.images.length !== 0 ? (
          <div className="galleryCollapse">
            <div className="collapseContent">
              <div
                className="divsLabels"
                onClick={() => setImgCollapse(!imgCollapse)}
              >
                <label>{t("MovieModal.images")}</label>
                <ArrowDown />
              </div>
              <Collapse className="collapse" in={imgCollapse}>
                <Carousel mediaLabel={"images"} media={info?.images} />
              </Collapse>
            </div>
          </div>
        ) : (
          <></>
        )}
        {info?.videos.length !== 0 ? (
          <div className="galleryCollapse">
            <div className="collapseContent">
              <div
                className="divsLabels"
                onClick={() => setVidCollapse(!vidCollapse)}
              >
                <label>{t("MovieModal.videos")}</label>
                <ArrowDown />
              </div>
              <Collapse className="collapse" in={vidCollapse}>
                <Carousel mediaLabel={"videos"} media={info?.videos} />
              </Collapse>
            </div>
          </div>
        ) : (
          <></>
        )}
        {info?.links !== undefined && info?.links.length !== 0 ? (
          <div className="galleryCollapse">
            <div className="collapseContent">
              <div
                className="divsLabels"
                onClick={() => setLinkCollapse(!linkCollapse)}
              >
                <label>{t("MovieModal.links")}</label>
                <ArrowDown />
              </div>
              <Collapse className="collapse" in={linkCollapse}>
                <Carousel
                  mediaLabel={"links"}
                  media={info?.links}
                  selectedLang={selectedLang}
                />
              </Collapse>
            </div>
          </div>
        ) : (
          <></>
        )}
        {info?.audio !== undefined && info?.audio.length !== 0 ? (
          <div className="galleryCollapse">
            <div className="collapseContent">
              <div
                className="divsLabels"
                onClick={() => setAudioCollapse(!audioCollapse)}
              >
                <label>{t("MovieModal.audios")}</label>
                <ArrowDown />
              </div>
              <Collapse className="collapse" in={audioCollapse}>
                <Carousel
                  mediaLabel={"audio"}
                  media={info?.audio}
                  selectedLang={selectedLang}
                />
              </Collapse>
            </div>
          </div>
        ) : (
          <></>
        )}
        {info?.documents !== undefined && info?.documents.length !== 0 ? (
          <div className="galleryCollapse">
            <div className="collapseContent">
              <div
                className="divsLabels"
                onClick={() => setDocCollapse(!docCollapse)}
              >
                <label>{t("MovieModal.documents")}</label>
                <ArrowDown />
              </div>
              <Collapse className="collapse" in={docCollapse}>
                <Carousel
                  mediaLabel={"documents"}
                  media={info?.documents}
                  selectedLang={selectedLang}
                />
              </Collapse>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Mobile;
