import React, { useState, useEffect } from "react";

import LanguageSelect from "../components/LanguageSelect/languageSelect";
import Map from "../components/Map/Map";
import settings from "../api/config";

import "../App.css";

export default function HomePage() {
  const [movieLocations, setMovieLocations] = useState([]);
  const [selectedLang, setSelectedLang] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const url = `${settings.BASE_URL}/movie-locations/all`;
      const res = await fetch(url);
      const data = await res.json();

      return data;
    } catch (error) {
      console.log("Error fetching movie locations:", error);
      throw error;
    }
  };

  const loadMovieLocs = async () => {
    setLoading(true);
    try {
      const locations = await fetchData();
      setMovieLocations(locations);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Get all movie locations from API
    loadMovieLocs();
  }, []);

  return (
    <>
      <LanguageSelect setSelectedLang={setSelectedLang} selectedLang={selectedLang} />
      <Map loading={loading} movieLocations={movieLocations} selectedLang={selectedLang} />
    </>
  );
}
