import React, { useEffect, useState, useRef, useCallback } from "react";
import TabPanel from "@mui/lab/TabPanel";
import Slider from "react-slick";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import DocumentIcon from "../../assets/icons/documentsIcon.png";
import AudioIcon from "../../assets/icons/audioIcon.png";
import LinkIcon from "../../assets/icons/linkIcon.svg";
import LinkGlobeIcon from "@mui/icons-material/Language";
import UploadIcon from "@mui/icons-material/Download";

import settingsApi from "../../api/config";
import "./Tab.css";

const ModalTab = ({ value, data, mediaIndex }) => {
  const settings = {
    className: "dataSlider",
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  const myCarousel = useRef(null);
  const { t } = useTranslation();
  const [content, setContent] = useState(data?.Images);

  const changeInfo = useCallback(() => {
    if (value === "img") {
      setContent(data?.Images);
    } else if (value === "vid") {
      setContent(data?.Videos);
    } else if (value === "links") {
      setContent(data?.Links);
    } else if (value === "audio") {
      setContent(data?.Audio);
    } else if (value === "doc") {
      setContent(data?.AtlasTranslations[mediaIndex]?.Documents);
    }
  }, [data, mediaIndex, value]);

  useEffect(() => {
    changeInfo();
  }, [value, changeInfo]);

  const renderSlider = () => {
    if (value === "img") {
      return (
        <div className="slider">
          <Slider ref={myCarousel} {...settings}>
            {content.map((image) => (
              <img
                key={image.id}
                className="sliderImg"
                src={`${settingsApi.BASE_URL}${image.formats?.medium ? image.formats?.medium.url : image.url}`}
                alt="media"
              ></img>
            ))}
          </Slider>
        </div>
      );
    } else if (value === "vid") {
      return (
        <div className="slider">
          <Slider ref={myCarousel} {...settings}>
            {content.map((video) => (
              <video
                key={video.id}
                src={`${settingsApi.BASE_URL}${video.url}`}
                controls
                className="sliderImg"
                alt="Location pin video"
              ></video>
            ))}
          </Slider>
        </div>
      );
    } else if (value === "links" && data?.Links.length > 0) {
      return (
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item lg={6} md={6} sm={6}>
            <div className="sliderLinkContent">
              <div style={{ maxHeight: "250px" }}>
                {content.map((doc) => (
                  <div key={doc.id} className="sliderContent">
                    {/* <img src={doc.ext !== ".pdf" ? AudioIcon : DocumentIcon} alt="empty state" /> */}
                    <LinkGlobeIcon color="#391D69" fontSize="large" />
                    <div className="docInfo">
                      <p className="docName">{doc.urlDescription}</p>
                      {/* <span>{selectedLang}</span> */}
                    </div>
                    <div className="spacer"></div>
                    <img
                      src={LinkIcon}
                      className="linkIcon"
                      onClick={() => {
                        window.open(`${doc.url}`, "__blank");
                      }}
                      alt="Open link"
                    />
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      );
    } else if (value === "audio" && data?.Audio.length > 0) {
      return (
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item lg={6} md={6} sm={6}>
            <div className="sliderLinkContent">
              <div style={{ maxHeight: "250px" }}>
                {content.map((doc) => (
                  <div key={doc.id} className="sliderContent">
                    <img src={AudioIcon} alt="empty state" />
                    <div className="docInfo">
                      <p className="docName">{doc.name}</p>
                      {/* <span>{selectedLang}</span> */}
                    </div>
                    <div className="spacer"></div>
                    <UploadIcon
                      className="downloadIcon"
                      onClick={() => {
                        window.open(`${settingsApi.BASE_URL}${doc.url}`, "__blank");
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      );
    } else if (value === "doc" && data?.AtlasTranslations[mediaIndex]?.Documents.length > 0) {
      return (
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item lg={6} md={6} sm={6}>
            <div className="sliderLinkContent">
              <div style={{ maxHeight: "250px" }}>
                {content.map((doc) => (
                  <div key={doc.id} className="sliderContent">
                    <img src={DocumentIcon} alt="empty state" />
                    <div className="docInfo">
                      <p className="docName">{doc.name}</p>
                      {/* <span>{selectedLang}</span> */}
                    </div>
                    <div className="spacer"></div>
                    <UploadIcon
                      className="downloadIcon"
                      onClick={() => {
                        window.open(`${settingsApi.BASE_URL}${doc.url}`, "__blank");
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <TabPanel className="tabItem" value={value}>
        {content?.length > 0 ? (
          renderSlider()
        ) : (
          <>
            {value === "img" && (
              <div className="tabEmptyState">
                <div className="tabEmptyStateContent">
                  <p>{t("MovieModal.es_images")}</p>
                </div>
              </div>
            )}

            {value === "vid" && (
              <div className="tabEmptyState">
                <div className="tabEmptyStateContent">
                  <p>{t("MovieModal.es_videos")}</p>
                </div>
              </div>
            )}

            {value === "doc" && (
              <div className="tabEmptyState">
                <div className="tabEmptyStateContent">
                  <p>{t("MovieModal.es_documents")}</p>
                </div>
              </div>
            )}
          </>
        )}
      </TabPanel>
    </>
  );
};

export default ModalTab;
