import React, { useEffect, useState, useContext } from "react";
import DataTab from "../Tabs/Tab";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import Modal from "@mui/material/Modal";
import ReactLoading from "react-loading";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import Mobile from "./Mobile";
import { FilterContext } from "../../context/FilterContext";

import { UseWindowSize, getTypeTranslation } from "../../utils/utils";
import { HideAndShowTxtComponent } from "../../utils/utils";
import settings from "../../api/config";

import CloseIcon from "@mui/icons-material/CloseRounded";
import MovieIcon from "../../assets/icons/movieIcon.png";
import LocationIcon from "../../assets/icons/locationIcon.png";
import FilmContentIcon from "../../assets/icons/filmContent.png";
import FilmIcon from "../../assets/icons/film.png";
import ExperiencesIcon from "../../assets/icons/experiences.png";
import "../InfoModal/InfoModal.css";

const InfoModal = ({ isOpen, handleModalClose, selectedMovie, selectedLang }) => {
  const [tabValue, setTabValue] = useState("img");
  const [translationIndex, setTranslationIndex] = useState();
  const [englishIndex, setEnglishIndex] = useState(0);
  const [mediaIndex, setMediaIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);

  const [windowHeight, windowWidth] = UseWindowSize();

  const { appliedFilters, setAppliedFilters } = useContext(FilterContext);
  const { filters } = useContext(FilterContext);

  const [info, setInfo] = useState();

  const { t } = useTranslation();

  const fetchLocation = async (locationId) => {
    try {
      const url = `${settings.BASE_URL}/movie-locations/${locationId}`;
      const res = await fetch(url);
      const data = await res.json();

      return data;
    } catch (error) {
      console.log("Error fetching movie location by ID:", error);
      throw error;
    }
  };

  const loadMovieData = async (locationId) => {
    setLoading(true);

    let movieData = [];
    try {
      movieData = await fetchLocation(locationId);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }

    setLocation(movieData);
    setLoading(false);

    const index = movieData.movie.Translations.findIndex(
      (object) => object.__component === `translations.${selectedLang}`,
    );

    const engIndex = movieData.movie.Translations.findIndex((object) => object.__component === `translations.english`);

    const mediaIndex = movieData.AtlasTranslations.findIndex(
      (translation) => translation.__component === `documents-tags.${selectedLang}`,
    );

    setTranslationIndex(index);
    setMediaIndex(mediaIndex);
    setEnglishIndex(engIndex);
  };

  useEffect(() => {
    loadMovieData(selectedMovie.id);
  }, [selectedMovie]);

  useEffect(() => {
    if (translationIndex === undefined) return;

    const translatedSynopsis = location?.movie?.Translations[translationIndex]?.Synopsis;
    const synopsis = translatedSynopsis ? translatedSynopsis : location?.movie?.Translations[englishIndex]?.Synopsis;

    const object = {
      id: location?.movie.id,
      translatedTitle: "",
      location: `${location.location.City}, ${location.location.Country}`,
      synopsis: synopsis,
      images: location?.Images,
      videos: location?.Videos,
      audio: location?.Audio,
      links: location?.Links,
      type: location?.type,
      title: location?.title,
      description: location?.description,
      origMovTitle: location?.movie?.OriginalTitle,
    };

    console.log("OBJECT: ", object);

    const translatedTitle = location.movie.Translations[translationIndex]?.TitleTranslation;
    if (translatedTitle && translatedTitle?.trim() !== "") {
      object.translatedTitle = location.movie.Translations[translationIndex]?.TitleTranslation;
    } else object.translatedTitle = location?.movie.OriginalTitle;

    setInfo((prevState) => ({
      ...prevState,
      id: object.id,
      translatedTitle: object.translatedTitle,
      location: object.location,
      synopsis: object.synopsis,
      images: object.images,
      videos: object.videos,
      audio: object.audio,
      links: object?.links,
      type: object?.type,
      title: object?.title,
      description: object?.description,
      origMovTitle: object?.origMovTitle,
    }));
  }, [translationIndex]);

  useEffect(() => {
    if (mediaIndex === undefined) return;

    const object = {
      tags: location?.AtlasTranslations[mediaIndex]?.Tags,
      transTitle: location?.AtlasTranslations[mediaIndex]?.title,
      transDescription: location?.AtlasTranslations[mediaIndex]?.description,
      documents: location?.AtlasTranslations[mediaIndex]?.Documents,
    };

    setInfo((prevState) => ({
      ...prevState,
      tags: object.tags,
      transTitle: object.transTitle,
      transDescription: object.transDescription,
      documents: object.documents,
    }));
  }, [mediaIndex]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filterByMovie = () => {
    let appliedFiltersCopy = appliedFilters.map((object) => ({ ...object }));

    appliedFiltersCopy = [];

    const filterObject = filters.find(
      (object) => object.name === location.movie.Translations[translationIndex].TitleTranslation,
    );

    appliedFiltersCopy.push(filterObject);
    setAppliedFilters(appliedFiltersCopy);

    handleModalClose();
  };

  const setBackColor = (movieType) => {
    switch (movieType) {
      case "Film":
        return "#D4AF37";
      case "About the Film":
        return "#009999";
      case "Experiences":
        return "#391D69";
      default:
        return "#000000";
    }
  };

  const setBackgroundImage = (type) => {
    switch (type) {
      case "Film":
        return `url(${settings.BASE_URL}${location?.movie?.FavoritePhotogram?.url})`;
      default:
        return "none";
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {windowWidth > 480 ? (
          <div className="infoModal">
            {loading ? (
              <div className="modalLoadingContainer">
                <ReactLoading type="spin" color="#08798E" />
              </div>
            ) : (
              <div className="movieModalContent">
                <div style={{ backgroundColor: setBackColor(location?.type) }}>
                  <div className="header">
                    <Grid container>
                      <Grid item lg={7} md={7} sm={7}>
                        {info?.type === "About the Film" || info?.type === "Experiences" ? (
                          <h1>
                            {info?.transTitle === "undefined" || !info?.transTitle ? info?.title : info?.transTitle}
                          </h1>
                        ) : (
                          <h1>{info?.translatedTitle}</h1>
                        )}
                        <div
                          style={{
                            display: "inline-block",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            src={MovieIcon}
                            style={{
                              verticalAlign: "middle",
                              marginRight: "10px",
                            }}
                            alt="Movie icon"
                          />
                          <p
                            style={{
                              textTransform: "uppercase",
                              fontSize: "18px",
                              display: "inline",
                              verticalAlign: "middle",
                            }}
                          >
                            {info?.origMovTitle}
                          </p>

                          <img
                            src={LocationIcon}
                            style={{
                              verticalAlign: "middle",
                              marginRight: "10px",
                              marginLeft: "50px",
                            }}
                            alt="Location icon"
                          />
                          <p
                            style={{
                              fontSize: "18px",
                              display: "inline",
                              verticalAlign: "middle",
                            }}
                          >
                            {info?.location}
                          </p>
                        </div>
                        {info?.type === "About the Film" || info?.type === "Experiences" ? (
                          <HideAndShowTxtComponent
                            text={
                              info?.transDescription === "undefined" || !info?.transDescription
                                ? info?.description
                                : info?.transDescription
                            }
                            color={"#EE940D"}
                          />
                        ) : (
                          <HideAndShowTxtComponent text={info?.synopsis} color={"#000000"} />
                        )}
                        {mediaIndex !== -1 ? (
                          <div className="modalTags">
                            {info?.tags?.length > 0 &&
                              info?.tags.map((tag) => (
                                <span key={tag.id} className={`movieTag tag-${info?.type?.replaceAll(" ", "")}`}>
                                  {tag?.TagName}
                                </span>
                              ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item lg={5} md={5} sm={5}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "10px",
                            marginTop: "10px",
                            marginLeft: "170px",
                          }}
                        >
                          {info?.type === "Film" ? <img src={FilmIcon} alt="About the film icon" /> : <></>}
                          {info?.type === "About the Film" ? (
                            <img src={FilmContentIcon} alt="Film content icon" />
                          ) : (
                            <></>
                          )}
                          {info?.type === "Experiences" ? <img src={ExperiencesIcon} /> : <></>}
                          <p
                            style={{
                              textTransform: "uppercase",
                              fontSize: "20px",
                              paddingLeft: info?.type === "Film" ? "25px" : "none",
                            }}
                          >
                            {t(getTypeTranslation(info?.type))}
                          </p>
                        </div>
                      </Grid>
                    </Grid>

                    <CloseIcon
                      className="infoClose"
                      onClick={() => {
                        handleModalClose();
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    backgroundImage: setBackgroundImage(info?.type),
                    backgroundSize: "cover",
                    width: "100%",
                  }}
                >
                  <div className={info?.type === "Film" ? "filmTabs" : "modalTabs"}>
                    {info?.type === "Film" ? (
                      <></>
                    ) : (
                      <TabContext value={tabValue}>
                        <TabList
                          onChange={handleChange}
                          className="dataTabs"
                          sx={{ ".MuiTab-root": { background: info?.type === "Experiences" ? "#391D69" : "#009999" } }}
                        >
                          {info?.images.length !== 0 ? <Tab label={t("MovieModal.images")} value="img" /> : null}
                          {info?.videos.length !== 0 ? <Tab label={t("MovieModal.videos")} value="vid" /> : null}
                          {(info?.links !== undefined && info.links.length !== 0) || info?.links?.url === "" ? (
                            <Tab label={t("MovieModal.links")} value="links" />
                          ) : null}
                          {info?.audio !== undefined && info.audio.length !== 0 ? (
                            <Tab label={t("MovieModal.audios")} value="audio" />
                          ) : null}
                          {info?.documents !== undefined && info.documents.length !== 0 ? (
                            <Tab label={t("MovieModal.documents")} value="doc" />
                          ) : null}
                        </TabList>

                        <DataTab value={tabValue} data={location} mediaIndex={mediaIndex} selectedLang={selectedLang} />
                      </TabContext>
                    )}

                    <div className="movieModalBtns">
                      <a href={"https://www.cined.eu/movies/" + location?.movie.id} target="__blank">
                        <button className="modalBtn">
                          <p
                            style={{
                              textTransform: "uppercase",
                              fontSize: "13px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              margin: 0,
                              fontWeight: "bold",
                            }}
                          >
                            {t("MovieModal.watch")}
                          </p>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="movieModalOverlay"></div>
          </div>
        ) : (
          <Mobile
            image={`${settings.BASE_URL}${location?.movie?.PhotoGallery?.Photo[0].url}`}
            info={info}
            selectedLang={selectedLang}
            handleModal={handleModalClose}
            filterLocation={filterByMovie}
          ></Mobile>
        )}
      </Modal>
    </>
  );
};

export default InfoModal;
