import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Translations = {
  PT: "portuguese",
  SP: "spanish",
  CZ: "czech",
  FI: "finnish",
  RO: "romanian",
  BG: "bulgarian",
  IT: "italian",
  HR: "croatian",
  LT: "lithuanian",
  DE: "deutsch",
  FR: "french",
  US: "english",
  EL: "greek",
  TR: "turkish",
};

export const LocaleTranslations = {
  pt: "PT",
  es: "SP",
  sp: "SP",
  cs: "CZ",
  cz: "CZ",
  fi: "FI",
  ro: "RO",
  bg: "BG",
  it: "IT",
  hr: "HR",
  lt: "LT",
  de: "DE",
  fr: "FR",
  en: "US",
  us: "US",
  el: "EL",
  tr: "TR",
};

export const PIN_TYPES = {
  "Film": "Film",
  "FilmContent": "About the Film",
  "Experiences": "Experiences"
};

export const UseWindowSize = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerHeight, window.innerWidth]);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

const MAX_LENGTH = 334;

export const HideAndShowTxtComponent = ({ text, color }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const textToDisplay = isExpanded ? text : (text || "").slice(0, MAX_LENGTH);

  const showToggle = (text || "").length > MAX_LENGTH;

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const ellipsis = showToggle && !isExpanded ? "..." : "";

  return (
    <div>
      <p>
        {textToDisplay}
        {ellipsis}
        {showToggle && (
          <span onClick={toggleText} style={{ color: color, cursor: "pointer" }}>
            {isExpanded ? ` ${t('MovieModal.less')}`: ` ${t('MovieModal.more')}`}
          </span>
        )}
      </p>
    </div>
  );
};

export const getTypeTranslation = (pinType) => {
  let typetranslation = pinType;
  switch (pinType) {
    // case "Film"
    case PIN_TYPES.Film:
      typetranslation = "Movie.type_film";
      break;
    // case "About the Film"
    case PIN_TYPES.FilmContent:
      typetranslation = "Movie.type_film_content";
      break;
    // case "Experiences"
    case PIN_TYPES.Experiences:
      typetranslation = "Movie.type_experience";
      break;
    default:
      console.log("Movie type key not found");
  }

  return typetranslation;
}