import { nanoid } from "nanoid";

import { PIN_TYPES, getTypeTranslation } from "../../utils/utils";
import { TagList } from "../../utils/taglist";

export const getAllFilters = (movieLocations, selectedLang) => {
  let allLocations = [];
  let allMovies = [];
  let allFilters = [];

  for (let i = 0; i < movieLocations.length; i++) {
    const locationName = `${movieLocations[i].location.City}, ${movieLocations[i].location.Country}`;
    allLocations.push(locationName);

    const movieName = movieLocations[i].movie?.OriginalTitle;
    allMovies.push(movieName);
  }

  const uniqueTags = TagList;
  const uniqueLocations = [...new Set(allLocations)];
  const uniqueMovies = [...new Set(allMovies)];

  const pinTypes = Object.values(PIN_TYPES);
  for (let i = 0; i < pinTypes.length; i++) {
    allFilters.push({
      id: nanoid(),
      type: "type",
      name: getTypeTranslation(pinTypes[i]),
      value: pinTypes[i],
    });
  }

  for (let k = 0; k < uniqueTags.length; k++) {
    //add unique tags to global array
    allFilters.push({
      id: nanoid(),
      type: "tag",
      name: uniqueTags[k][selectedLang],
      value: uniqueTags[k].english,
    });
  }

  for (let k = 0; k < uniqueLocations.length; k++) {
    //add unique locations to global array
    allFilters.push({
      id: nanoid(),
      type: "location",
      name: uniqueLocations[k],
    });
  }

  for (let k = 0; k < uniqueMovies.length; k++) {
    //add unique movies to global array
    allFilters.push({
      id: nanoid(),
      type: "movie",
      name: uniqueMovies[k],
    });
  }

  return allFilters;
};
