import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import CardMovie from "../Cards/CardMovie";
import InfoModal from "../InfoModal/InfoModal";

import CloseIcon from "@mui/icons-material/CloseRounded";
import "./LocationModal.css";

const LocationModal = ({ isOpen, handleModalClose, setModalLocationStatus, locationMovies, selectedLang }) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [movieLocation, setMovieLocation] = useState();

  const { t } = useTranslation();

  const handleClick = useCallback((movieLocation) => {
    setModalStatus(true);
    setMovieLocation(movieLocation);
  }, []);

  // close modal
  const closeLocationModal = useCallback(() => {
    setModalStatus(false);
  }, []);

  //close location modal when the next is open
  useEffect(() => {
    if (modalStatus) {
      setModalLocationStatus(false);
    }
  }, [modalStatus, setModalLocationStatus]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="locationModal">
          <div className="locationModalHeader">
            <h2>
              {t("LocationModal.header")}{" "}
              {`${locationMovies[0]?.location?.City}, ${locationMovies[0]?.location?.Country}`} |{" "}
              <span>
                {locationMovies?.length} {t("LocationModal.results")}
              </span>
            </h2>

            <CloseIcon className="closeIcon" onClick={() => setModalLocationStatus(false)}></CloseIcon>
          </div>
          <div className="locationModalBody">
            <Grid container rowSpacing={2}>
              {locationMovies.map((movieLocation) => (
                <Grid item md={4} xs={12} key={movieLocation._id} className="movieGridItem">
                  <CardMovie
                    movieLocation={movieLocation}
                    onClick={() => handleClick(movieLocation)}
                    selectedLang={selectedLang}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </Modal>
      {modalStatus && (
        <InfoModal
          isOpen={modalStatus}
          handleModalClose={closeLocationModal}
          selectedMovie={movieLocation}
          selectedLang={selectedLang}
        />
      )}
    </>
  );
};

export default LocationModal;
