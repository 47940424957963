import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import * as L from "leaflet";
import i18next from "i18next";
import MarkerClusterGroup from "react-leaflet-markercluster";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { MapContainer, Marker, ZoomControl } from "react-leaflet";

import Loading from "../../components/loading/Loading";
import LocationModal from "../LocationModal/LocationModal";
import Filters from "../../templates/Filters";
import Footer from "../Footer/Footer";
import { UseWindowSize } from "../../utils/utils";
import { FilterContext } from "../../context/FilterContext";
import { getAllFilters } from "./MapUtils";

import BlackMarker from "../../assets/icons/CINED_Mark_black.svg";
import BlueMarker from "../../assets/icons/CINED_Mark_blue.svg";
import GoldMarker from "../../assets/icons/CINED_Mark_gold.svg";
import ColorMarker from "../../assets/icons/CINED_Mark_3color.svg";

import mapStyles from "../../styles/mapStyle";

import "leaflet/dist/leaflet.css"; // sass
import "react-leaflet-markercluster/dist/styles.min.css"; // sass
import "./Map.css";

const Map = ({ loading, movieLocations, selectedLang }) => {
  const markerRef = useRef(null);

  const [windowWidth] = UseWindowSize();

  // Filters state
  const { filters, setFilters } = useContext(FilterContext);
  const { appliedFilters, setAppliedFilters } = useContext(FilterContext);

  const [appliedTypeFilters, setAppliedTypeFilters] = useState([]);
  const [loadedTags, setLoadedTags] = useState(false);
  const [filteredMovies, setFilteredMovies] = useState([]);
  const [locations, setLocations] = useState([]);

  // Modal state
  const [modalStatus, setModalStatus] = useState(false);
  const [selectedLocationMovies, setSelectedLocationMovies] = useState([]);

  // Set pins per location when filters change
  useEffect(() => {
    const singleLocations = [];

    // Verify if the location already exists
    for (let i = 0; i < filteredMovies.length; i++) {
      const locationExists = singleLocations.find((l) => l._id === filteredMovies[i].location._id);

      if (!locationExists) {
        singleLocations.push(filteredMovies[i].location);
      }
    }

    setLocations(singleLocations);
  }, [filteredMovies]);

  // Refresh filter elements when language changes
  useEffect(() => {
    if (selectedLang) {
      const allFilters = getAllFilters(movieLocations, selectedLang);
      setFilters(allFilters);
      setLoadedTags(true);
    }
  }, [movieLocations, selectedLang]);

  // Reset applied filters when language changes
  useEffect(() => {
    setAppliedFilters([]);
  }, [i18next.language]);

  // Get clicked location's movies
  const getSelectedLocationMovies = (location) => {
    const moviesFound = [];

    for (let i = 0; i < filteredMovies.length; i++) {
      if (filteredMovies[i].location._id === location._id) {
        moviesFound.push(filteredMovies[i]);
      }
    }

    setSelectedLocationMovies(moviesFound);
  };

  // Open location modal
  const openLocationModal = (location) => {
    getSelectedLocationMovies(location);
    setModalStatus(true);
  };

  // Close location modal
  const closeLocationModal = () => {
    setSelectedLocationMovies([]);
    setModalStatus(false);
  };

  // Get the auto complete value (for movie, location, or tag filters)
  const handleChange = (event, value) => {
    event.preventDefault();

    const autocomplete = document.querySelector(".MuiAutocomplete-input");

    const filterApplied = value;
    let filters = appliedFilters;

    const filterFound = filterApplied?.id && filters.some((filter) => filter.id === filterApplied.id);

    if (!filterFound) {
      filters = [...appliedFilters, filterApplied];
      setAppliedFilters(filters);
    }

    autocomplete.value = "";
  };

  // Handle type filters
  const handleChangeTypeContent = (value) => {
    setAppliedTypeFilters(value);
  };

  const tagFilter = (tag) => {
    const tagSelected = tag;
    let filters = appliedFilters;

    const tagFound = filters.some((tag) => tag.id === tagSelected.id);

    if (!tagFound) {
      filters = [...appliedFilters, tagSelected];
      setAppliedFilters(filters);
    }
  };

  // Remove specific filter from the id
  const removeFilter = (id) => {
    setAppliedFilters(appliedFilters.filter((filter) => filter.id !== id));
  };

  const clearAllFilters = () => {
    setAppliedFilters([]);
  };

  // Apply filters logic
  const filterLocations = useCallback(
    (appliedFilters, appliedTypeFilters) => {
      const filteredMovieLocations = [];

      const filteredMovieLoc = movieLocations.filter((movieLoc) =>
        appliedTypeFilters.some((filter) => filter.value === movieLoc.type),
      );

      if (appliedFilters.length === 0) {
        setFilteredMovies(filteredMovieLoc);
        return;
      }

      for (let i = 0; i < appliedFilters.length; i++) {
        for (let j = 0; j < filteredMovieLoc.length; j++) {
          const movieTags = filteredMovieLoc[j].AtlasTranslations.English.Tags;

          if (
            appliedFilters[i]?.type === "location" &&
            `${filteredMovieLoc[j].location.City}, ${filteredMovieLoc[j].location.Country}` === appliedFilters[i].name
          ) {
            const movieLocationFound = filteredMovieLocations.some(
              (movieLocation) => movieLocation._id === filteredMovieLoc[j]._id,
            );

            if (!movieLocationFound) {
              filteredMovieLocations.push(filteredMovieLoc[j]);
            }
          } else if (
            appliedFilters[i]?.type === "movie" &&
            filteredMovieLoc[j].movie.OriginalTitle === appliedFilters[i].name
          ) {
            const movieLocationFound = filteredMovieLocations.some(
              (movieLocation) => movieLocation._id === filteredMovieLoc[j]._id,
            );
            if (!movieLocationFound) {
              filteredMovieLocations.push(filteredMovieLoc[j]);
            }
          } else if (appliedFilters[i]?.type === "tag" && movieTags.includes(appliedFilters[i].value)) {
            const movieLocationFound = filteredMovieLocations.some(
              (movieLocation) => movieLocation._id === filteredMovieLoc[j]._id,
            );

            if (!movieLocationFound) {
              filteredMovieLocations.push(filteredMovieLoc[j]);
            }
          }
        }
      }

      setFilteredMovies(filteredMovieLocations);
    },
    [movieLocations],
  );

  useEffect(() => {
    filterLocations(appliedFilters, appliedTypeFilters);
  }, [appliedFilters, appliedTypeFilters, filterLocations]);

  // Atribuição location - icon --------------------------------------
  const locationIconMap = {};
  const locationCounts = {};

  if (filteredMovies.length > 0) {
    filteredMovies.forEach((movieLoc) => {
      const locationKey = `${movieLoc?.location?.lat}-${movieLoc?.location?.long}`;
      if (!locationCounts[locationKey]) {
        locationCounts[locationKey] = {
          count: 0,
          typeSet: new Set(),
        };
      }
      locationCounts[locationKey].count += 1;
      locationCounts[locationKey].typeSet.add(movieLoc.type);

      locationIconMap[locationKey] = getIconForLocation(
        movieLoc.type,
        locationCounts[locationKey].count,
        locationCounts[locationKey].typeSet.size,
      );
    });
  }

  function getIconForLocation(type, count, distinctTypeCount) {
    let iconUrl = BlueMarker;

    if (distinctTypeCount === 1) {
      if (type === "Experiences") {
        iconUrl = BlackMarker;
      } else if (type === "Film") {
        iconUrl = GoldMarker;
      } else if (type === "About the Film") {
        iconUrl = BlueMarker;
      }
    } else if (distinctTypeCount > 1) {
      iconUrl = ColorMarker;
    }

    return L.icon({
      iconUrl: iconUrl,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <MapContainer center={[48, 1]} zoom={0} zoomControl={false} tap={false} minZoom={4} maxZoom={9}>
          <ReactLeafletGoogleLayer apiKey="AIzaSyCbll8tLBXWpHeb0VDuCTLi8NVwWG7Y7Hs" styles={mapStyles.main} />
          <ZoomControl position="bottomright" />

          {locations.length > 0 ? (
            <MarkerClusterGroup removeOutsideVisibleBounds={false} showCoverageOnHover={false} maxClusterRadius={40}>
              {locations.length > 0 &&
                locations.map((location) => (
                  <Marker
                    ref={markerRef}
                    eventHandlers={{
                      click: () => {
                        openLocationModal(location);
                      },
                    }}
                    key={`${location?.lat}-${location?.long}`}
                    position={[location.lat, location.long]}
                    icon={locationIconMap[`${location?.lat}-${location?.long}`]}
                  />
                ))}
            </MarkerClusterGroup>
          ) : (
            <></>
          )}
        </MapContainer>
      )}

      <Filters
        filters={filters}
        handleChange={handleChange}
        handleChangeTypeContent={handleChangeTypeContent}
        appliedFilters={appliedFilters}
        removeFilter={removeFilter}
        tagFilter={tagFilter}
        clearFilters={clearAllFilters}
        windowWidth={windowWidth}
        loadedTags={loadedTags}
      />

      <LocationModal
        isOpen={modalStatus}
        handleModalClose={closeLocationModal}
        setModalLocationStatus={(status) => setModalStatus(status)}
        locationMovies={selectedLocationMovies}
        selectedLang={selectedLang}
      />

      <Footer />
    </>
  );
};

export default Map;
