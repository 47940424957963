import React, { useState } from "react";
import { useEffect } from "react";

import { FilterContext } from "./context/FilterContext";
import HomePage from "./pages/HomePage";

import { UseWindowSize } from "./utils/utils";

function App() {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [zoom, setZoom] = useState([]);

  return (
    <div className="App">
      <FilterContext.Provider
        value={{
          filters,
          setFilters,
          appliedFilters,
          setAppliedFilters,
          zoom,
          setZoom,
        }}
      >
        <HomePage />
      </FilterContext.Provider>
    </div>
  );
}

export default App;
