import React from "react";

import { LocaleTranslations, Translations } from "../../utils/utils";
import ArrowDown from "@mui/icons-material/KeyboardArrowDownRounded";

import "./languageSelect.css";

import i18next, { loadLanguages } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelect = ({ setSelectedLang, selectedLang }) => {
  const { t } = useTranslation();

  const [initialLang, setInitialLang] = useState("");

  const translationsMap = new Map(Object.entries(Translations));

  const sortedMap = new Map(
    [...translationsMap].sort((a, b) => (b[1] > a[1] ? -1 : 1))
  );

  let translations = [...sortedMap.keys()];

  const localesMap = new Map(Object.entries(LocaleTranslations));

  const checkMenu = document.querySelector("#dropdown");

  useEffect(() => {
    if (i18next.language) {
      setInitialLang(i18next.language.split("-")[0]);
    }
  }, [i18next.language]);

  useEffect(() => {
    const translationKey = localesMap.get(initialLang.toLowerCase());
    const translationValue = translationsMap.get(translationKey);
    setSelectedLang(translationValue);
    i18next.changeLanguage(initialLang);
  }, [initialLang]);

  const handleClick = (lang) => {
    checkMenu.checked = false;

    if (lang === "us") {
      lang = "en";
    }

    const translationAcronym = localesMap.get(lang);

    const translationString = translationsMap.get(translationAcronym); //precisa de retornar a string completa

    setSelectedLang(translationString);
    i18next.changeLanguage(lang.toUpperCase());
  };

  const capitalizeString = (string) => {
    // converting first letter to uppercase
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);

    return capitalized;
  };

  return (
    <div className="translationsSection">
      <input
        className="dropdown"
        type="checkbox"
        id="dropdown"
        name="dropdown"
      />
      <label className="for-dropdown" htmlFor="dropdown">
        <span className="lang">{selectedLang}</span>
        <ArrowDown className="arrow" />
      </label>

      <div className="dropMenu">
        {translations?.length &&
          translations?.map((translation) => (
            <span
              key={translation}
              onClick={() => handleClick(translation.toLowerCase())}
            >
              {capitalizeString(translationsMap.get(translation))}
            </span>
          ))}
      </div>
    </div>
  );
};

export default LanguageSelect;
